//import "bootstrap/dist/css/bootstrap.min.css";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
//import "./src/styles/sass/main.scss";

const addScript = url => 
{
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
}
  
export const onClientEntry = () => 
{
    window.onload = () => 
    {
        addScript("https://kit.fontawesome.com/f07522b7ab.js");
    }
} 